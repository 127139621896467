import Vue from 'vue'
import axios from 'axios'

// デフォルトステート
const defaultState = () => {
  return {
    // 集計結果オブジェクト
    aggregateResult: null,
    // 集計結果pdfデータ
    resultPdf: null,
    // 集計結果pdf生成のステート値
    isResultPdfGenerated: false,
  }
}

export default {
  namespaced: true,

  state: defaultState(),

  getters: {
    // 物件概要の取得
    getBuildingInfo(state){
      return state.aggregateResult.building_info
    },
    // 住戸部分の一次エネルギー消費量の合計
    getCommonEnergyConsumption(state){
      return state.aggregateResult.energy_consumptions.commonarea_energy_consumption
    },
    // 共用部分の一次エネルギー消費量の合計
    getHouseholdEnergyConsumption(state){
      return state.aggregateResult.energy_consumptions.household_energy_consumption
    },
    // 一次エネルギー消費量の合計
    getTotalEnergyConsumption(state){
      return state.aggregateResult.energy_consumptions.total_energy_consumption
    },
    // BEI情報
    getBeiInfo(state){
      return state.aggregateResult.bei_info
    },
    // 外皮性能集計表
    getEnvelopePerformance(state){
      return state.aggregateResult.envelope_performance
    },
    // 単位住戸の情報
    getHouseholdResultsTable(state){
      return state.aggregateResult.household_results_table
    },
    // 集計結果pdfデータ
    getResultPdf(state){
      return state.resultPdf
    },
    // 集計結果pdf生成のステート値
    getIsResultPdfGenerated(state){
      return state.isResultPdfGenerated
    }
  },

  mutations: {
    // stateのリセット
    reset(state, value){
        state = Object.assign(state, value)
    },
    // stateの更新
    update(state, values){
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
  },

  actions: {
    // stateのリセットを呼び出す
    reset({commit}){
        commit('reset', defaultState())
    },
    // stateの更新を呼び出す
    update({commit}, values){
        commit('update', values)
    },
    // 集計結果の取得
    async getAggregateResult({commit, rootGetters}) {
      const householdJobId = rootGetters['fileupload/getHouseholdJobId']
      const commonAreaJobId = rootGetters['fileupload/getCommonAreaJobId']
      const householdsList = rootGetters['fileupload/getHouseholdsList']
      const basicInfo = rootGetters['basicInfo/getBasicInfo']

      const request_json = {
        household_job_id: householdJobId,
        common_job_id: commonAreaJobId,
        basicInfo: basicInfo,
        householdsList: householdsList
      }

      return await axios.post('/api/aggregate', request_json)
        .then(response => {
            if (response.data['aggregate_result'] != null) {
              commit('update', {aggregateResult:response.data['aggregate_result']})
              return "success"
            }
            if (response.data['ver_2_pdf'] != null) {
              return "ver_2_pdf"
            }
        }).catch(error => {
            console.log(error)
            return "error"
        })
    },
    // 集計結果pdfデータの取得
    async generateResultPdf({commit, rootGetters}) {
      const householdJobId = rootGetters['fileupload/getHouseholdJobId']
      const commonAreaJobId = rootGetters['fileupload/getCommonAreaJobId']

      const request_json = {
        household_job_id: householdJobId,
        common_area_job_id: commonAreaJobId
      }
      await axios.post('/api/pdf', request_json, {responseType: 'arraybuffer'}) 
        .then(response => {
          commit('update', {resultPdf:response.data})
        })
    },
  }
}

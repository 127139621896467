import Vue from 'vue'

// デフォルトステート
const defaultState = () => {
  return {
    // 基本情報オブジェクト
    basicInfo: {
      // 建物名称
      buildingName: null,
      // 地域の区分 デフォルト値: 6 ⇒ 6地域
      chiki: 6,
      // 住棟全体の住戸数
      householdTotalNum: "",
      /**
       * 入力方法を表示する値
       * 0 ⇒ 当該住戸の仕様を用いて評価する
       * 1 ⇒ フロアごとの代表的な仕様を用いて評価する(フロア入力法) 
       * */
      evaluationMethod: 0,
      /**
       * 外皮性能の評価方法を表示する値
       * 0 ⇒ 住戸単位
       * 1 ⇒ 住棟単位平均
       * */
      envEvaluationMethod: 0,
      /**
       * 一次エネの評価方法を表示する値
       * true ⇒ 住戸部分＋共用部分
       * false ⇒ 住戸部分
       * */
      commonAreaType: true
    },
    // エラーリスト
    error: []
  }
}

export default {
  namespaced: true,

  state: defaultState(),

  getters: {
    // エラーリスト
    getError(state) {
        return state.error
    },
    // ステートの基本情報を取得する
    getBasicInfo(state) {
        return state.basicInfo
    },
    // 一次エネの評価方法を取得する
    getCommonAreaType(state) {
        return state.basicInfo.commonAreaType
    }
  },

  mutations: {
    // stateのリセット
    reset(state, value){
        state = Object.assign(state, value)
    },
    // stateの更新
    update(state, values){
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
    // stateのアペンド
    append(state, values){
        Object.keys(values).forEach(key => {
            Vue.set(state, key, state[key].concat(values[key]))
        })
    },
    // 基本情報の更新
    updateBasicInfo(state, values){
        Object.keys(values).forEach(key => {
            Vue.set(state.basicInfo, key, values[key])
        })
    }
  },

  actions: {
    // stateのリセットを呼び出す
    reset({commit}){
        commit('reset', defaultState())
    },
    // stateの更新を呼び出す
    update({commit}, values){
        commit('update', values)
    },
    // stateのアペンドを呼び出す
    append({commit}, values){
        commit('append', values)
    },
    // 基本情報の更新を呼び出す
    updateBasicInfo({commit}, values){
        commit('updateBasicInfo', values)
    }
  }
}

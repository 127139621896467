import Vue from 'vue'
import axios from 'axios'

const defaultState = () => {
  return {
    // 読み込み済みの共用部PDFファイル名
    commonAreaFileName: null,
    // 住宅タイプのリスト
    householdsTypeList: [],
    // 住宅のリスト
    householdsList: [],
    // 住宅のアップロード状態
    isHouseholdInfoImported: false,
    // Job ID
    householdJobId: [],    
    commonAreaJobId: null,
    // エラーリスト
    error: []
  }
}

export default {
  namespaced: true,

  state: defaultState(),

  getters: {
    // エラーリスト
    getError(state) {
        return state.error
    },

    //-------------------------------------------------------
    //            住戸部PDF
    //-------------------------------------------------------

    // 住戸部PDFアップロード処理のJobId
    getHouseholdJobId(state) {
        return state.householdJobId
    },    
    // 読込したファイル名
    getFileNames(state) {
        return state.householdsTypeList.map(function (el) { return el.filename })
    },
    // 住戸リスト
    getHouseholdsList(state) {
        return state.householdsList
    },
    // 住戸タイプの名称一覧
    getHouseholdsTypeList(state) {
        return state.householdsTypeList.map(function (el) { return el.householdType })
    },
    // 住戸のアップロード状態
    isHouseholdInfoImported(state) {
        return state.isHouseholdInfoImported
    },

    //-------------------------------------------------------
    //            共用部PDF
    //-------------------------------------------------------

    // 共用部PDFアップロード処理のJobId
    getCommonAreaJobId(state) {
        return state.commonAreaJobId
    },
    // 読み込み済みの共用部PDFファイル名
    getCommonAreaFileName(state) {
        return state.commonAreaFileName
    }
  },

  mutations: {
    // stateのリセット
    reset(state, value){
        state = Object.assign(state, value)
    },
    // stateの更新
    update(state, values){
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
    // stateのアペンド
    append(state, values){
        Object.keys(values).forEach(key => {
            Vue.set(state, key, state[key].concat(values[key]))
        })
    },
    // 住戸部分Pdfの削除
    deleteFile(state, value) {
        const index = state.householdsTypeList.map(function(elem) { return elem.filename }).indexOf(value)
        if (index > -1) {
            const householdType = state.householdsTypeList[index].householdType
            state.householdsList.forEach(ele => {
                if(ele.householdType == householdType) ele.householdType = ""
            })
            state.householdsTypeList.splice(index, 1)
        }

        if (state.householdsTypeList.length == 0) {
            state.isHouseholdInfoImported = false
            state.householdsList.forEach(value => {
                value.floor = ""
                value.householdNum = "",
                value.householdType = ""
            })
        }
    }
  },

  actions: {
    // stateのリセットを呼び出す
    reset({commit}){
        commit('reset', defaultState())
    },
    // エラーメッセージの追加
    addError({commit}, errorMessage){
        commit('append', {
            error: [errorMessage]
        })
    },
    // 唯一のエラーメッセージを設定
    setError({commit}, errorMessage){
        commit('update', {
            error: [errorMessage]
        })
    },
    // エラーをリセット
    clearError({commit}){
        commit('update', {
            error: [],
        })
    },
    // 住戸部分の一覧を消去
    clearHouseHoldsList({commit}){
        commit('update', {
            householdsList: []
        })
    },
    // 住戸部分の一覧を一括更新
    updateHouseHoldsList({commit}, householdsList){
        commit('update', {
            householdsList: householdsList
        })
    },
    // 住戸リストをインポート
    importHouseHoldsList({commit}, householdsList){
        commit('update', {
            error: [],
            householdsList: householdsList,
            isHouseholdInfoImported: true
        })
    },
    // 住戸情報のインポート状態の設定
    setHouseholdInfoImported({commit}, flag){
        commit('update', {isHouseholdInfoImported: flag})
    },
    // 住戸タイプの追加
    appendHouseholdTypes({commit}, household_types){
        commit('append', {householdsTypeList:household_types})
    },
    // 住戸部分Pdfの削除を呼び出す
    deleteFile({commit}, value) {
        commit('deleteFile', value)
    },
    // 住棟全体の住戸数によって住戸リストを作成する
    createHouseholdsListByHouseholdCnt({commit, state, rootGetters}) {
        const basicInfo = rootGetters['basicInfo/getBasicInfo']
        const diff = basicInfo.householdTotalNum - state.householdsList.length
        if (diff > 0) {
            for(let index = 0; index < diff; index ++){
                commit('append', {householdsList: {
                floor: "",
                householdNum: "",
                householdType: ""
                }})
            }
        } else if(diff < 0) {
            const abs = Math.abs(diff)

            let householdsList = state.householdsList
            householdsList.splice(householdsList.length - abs, abs)
            commit('update', {householdsList:householdsList})
        }
    },
    // 住戸のJobIDを追加
    appendHouseholdJobId({commit}, value){
        commit('append', value)
    },
    // 共用部のJobIDを更新
    updateCommonAreaJobId({commit}, value) {
        commit('update', value)
    },
    // 読み込み済みの共用部PDFファイル名を更新
    updateCommonAreaFileName({commit}, value) {
        commit('update', value)
    },

    //-------------------------------------------------------
    //            APIの処理
    //-------------------------------------------------------
    // Jobの実行状態の確認
    async checkJobStatus(context, data) {
        return await axios.get(`/api/${data.type}/${data.job_id}/status`)
            .then(response => {
                return response.data
            }).catch(error => {
                return error.response.data
            })
    },
    // 住宅タイプのリスト取得
    async getHouseholdTypes(context, job_id) {
      return await axios.get(`/api/household/${job_id}/result`)
        .then(response => {
            const household_types = response.data.household_types
            return household_types
        })
    },
  },
}

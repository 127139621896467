import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
import basicInfo from '@/store/modules/basicInfo'
import fileupload from '@/store/modules/fileupload'
import result from '@/store/modules/result'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        //バージョン番号
        version: '',
    },
    modules: {
        basicInfo,
        fileupload,
        result
    },
    getters: {
        // プログラムのバージョン番号
        getVersion(state){
        return state.version
        },
    },
    mutations: {
        // stateの更新
        update(state, values){
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
        },
    },
    actions: {
        // stateのリセットを呼び出す
        reset({dispatch}){
            dispatch('basicInfo/reset')
            dispatch('fileupload/reset')
            dispatch('result/reset')
        },

        //-------------------------------------------------------
        //            APIの処理
        //-------------------------------------------------------

        // バージョン番号の取得
        async getVersion({commit}) {
            return await axios.get('/api/version') 
            .then(response => {
            commit('update', {version: response.data.version})
        })
        }
    },
    plugins: [
        createPersistedState({storage: window.sessionStorage})
    ]
})
